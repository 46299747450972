@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");

@font-face {
  font-family: "Swera Demo";
  src: url("./fonts/SweraDemo.eot");
  src: url("./fonts/SweraDemo.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SweraDemo.woff") format("woff"),
    url("./fonts/SweraDemo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
body {
  padding: 0;
  margin: 0;
  font-family: "Swera Demo";
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  background-color: #f2f2f2;
}

p {
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-family: "Titillium Web", sans-serif;
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  padding: 0.6rem 0;
}

.logo-bg {
  background-image: url("./img/logo-bg.png");
  background-repeat: no-repeat;
  padding: 2rem 0;
  background-size: cover;
  background-position: bottom;
  padding-top: 11rem;
  // background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-repeat: no-repeat;

  @media all and (max-width: 768px) {
    padding-top: 2.5rem;
  }
}

.logo-container {
  @include center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-top: -12vh;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  img {
    max-width: 170px;
  }

  @media all and (max-width: 992px) {
    margin-top: 2rem;
    width: 200px;
    height: 200px;
    img {
      max-width: 140px;
    }
  }
}
.social {
  padding-top: 1rem;
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    li {
      width: 2.5rem;
      height: 2.5rem;
      background-color: #1591ff;
      border-radius: 50%;
      display: flex;
      margin-right: 0.6rem;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
        color: #fff;
        .icon {
          font-size: 1.3rem;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    ul {
      li {
        width: 2rem;
        height: 2rem;
        a {
          .icon {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@keyframes border-anim {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

@keyframes border-anim-1 {
  0% {
    left: 0;
  }
  100% {
    left: 80%;
  }
}

.banner {
  min-height: 100vh;
  background-image: url("./img/banner-img.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media all and (max-width: 992px) {
    padding-top: 6rem;
  }

  .border-1 {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #4a4a4a;
    top: 0;
    left: 10vw;

    &::before {
      content: "";
      position: absolute;
      height: 1.8rem;
      width: 4px;
      background-color: #f2b39c;
      top: 0;
      left: 0;
      animation: border-anim 5s 3s ease-in-out infinite alternate;
    }
  }
  .border-2 {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #4a4a4a;
    top: 0;
    left: 50vw;

    &::before {
      content: "";
      position: absolute;
      height: 1.8rem;
      width: 4px;
      background-color: #f2b39c;
      top: 0;
      left: 0;
      animation: border-anim 5s ease-in-out infinite alternate;
    }

    @media all and (max-width: 768px) {
      left: 85vw;
    }
  }

  .border-3 {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #4a4a4a;
    top: 10vh;
    left: 0;

    &::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 1.8rem;
      background-color: #f2b39c;
      top: 0;
      left: 0;
      animation: border-anim-1 10s ease-in-out infinite alternate;
    }
  }

  .border-4 {
    position: absolute;
    height: 1px;
    width: 49vw;
    background-color: #4a4a4a;
    bottom: 15vh;
    right: 0;

    &::before {
      content: "";
      position: absolute;
      height: 4px;
      width: 1.8rem;
      background-color: #f2b39c;
      bottom: 0;
      left: 0;
      animation: border-anim-1 5s ease-in-out infinite alternate;
    }
  }
}

.banner-img {
  max-width: 450px;
}

.small-heading {
  font-size: 5rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 1rem;
  line-height: 0.95;

  @media all and (max-width: 768px) {
    font-size: 3.4rem;
    line-height: normal;
    margin: 0;
    line-height: 1.2;
  }
}

.large-heading {
  font-size: 8rem;
  font-weight: bold;
  color: #fff;
  line-height: 0.95;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  span {
    color: #1591ff;
  }

  @media all and (max-width: 768px) {
    font-size: 5rem;
    line-height: normal;
    margin: 0;
    line-height: 1.2;
    span {
      font-size: 3rem;
    }
  }
}

.banner-link {
  background-color: #1591ff;
  color: #fff;
  padding: 1rem 8vw;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 0.3rem;
  text-transform: uppercase;

  @media all and (max-width:768px){
    padding: 1rem 3rem;
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

.banner-link-cont {
  margin-top: 3rem;
}

.slick-slide {
  padding-right: 10px;
}

.image-container {
  position: relative;
  width: 560px;
  height: 600px;
  overflow: hidden;
  border-radius: 0.4rem;
  transition: transform 300ms ease;
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: 100%;
    background-position: center;
    &:first-child {
      background: url("./img/before.jpeg");
      background-repeat: no-repeat;
      object-fit: cover;

      filter: grayscale(100%);
    }
    &:nth-child(2) {
      background: url("./img/after-1.jpg");
      background-repeat: no-repeat;
      width: 50%;

      object-fit: cover;
    }
  }

  .preview-slider {
    position: absolute;
    @include center;
    width: 100%;
    height: 100%;
    margin: 0;
    background: rgba(#f2f2f2, 0.3);
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    z-index: 2;
    transition: all 200ms linear;
    &:hover {
      background: rgba(#f2f2f2, 0.1);
    }
    &::-webkit-slider-thumb {
    }
  }

  @media all and (max-width: 768px) {
    width: 100%;
    height: 600px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    height: 400px;
  }
  @media all and (max-width: 320px) {
    width: 100%;
    height: 300px;
  }
}

.buttonRange {
  z-index: 0;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -50%);
  cursor: drag;
  .after {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(-70%, -50%);
    font-size: 1.1rem;
    color: #1591ff;
  }
  .before {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(70%, -50%);
    font-size: 1.1rem;
    color: #1591ff;
  }
}

.about-viking {
  background-color: #f2f2f2;
  padding: 6rem 0;
  @media all and (max-width: 768px) {
    padding: 3rem 0;
  }
}

.heading-one {
  font-size: 4rem;
  font-weight: 600;
  span {
    color: #1591ff;
  }

  @media all and (max-width: 768px) {
    font-size: 3rem;
  }
}

.heading-two {
  font-size: 3rem;
  font-weight: 600;
  span {
    color: #1591ff;
  }

  @media all and (max-width: 768px) {
    font-size: 2.5rem;
  }
}
.me {
  background-image: url("./img/banner-overlay.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  background-color: #000000;
  padding: 6rem 0;
  @media all and (max-width: 768px) {
    padding: 3rem 0;
  }
}

.white {
  color: #fff;
}

.heading-white {
  color: #1591ff;
  font-size: 3rem;
  font-weight: 600;
  span {
    color: #fff;
  }
}

.white-p {
  color: #c1c1c1;
  z-index: 1;
  margin-top: 1rem;
  span {
    font-weight: bold;
    color: #1591ff;
  }
}

.service-heading {
  text-transform: uppercase;
  font-size: 6rem;
  text-align: center;
  @media all and (max-width: 768px) {
    font-size: 4rem;
  }
}

.services {
  margin-top: 4rem;

  .combat {
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 6rem;
    font-weight: bold;
    line-height: 1;
    span {
      color: #1591ff;
    }

    @media all and (max-width: 768px) {
      font-size: 4rem;
    }
    @media all and (max-width: 511px) {
      font-size: 3rem;
    }
  }
}

.life-fitness {
 
  min-height: 50vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 6rem 0;

  h1{
    font-size: 8rem;
    font-weight: bold;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #fff;
    @media all and (max-width:992px){
      font-size: 4rem;
    }

    @media all and (max-width:768px){
      font-size: 3rem;
    }
  }
}

.text-white{
  color: #fff;
}


.hide{
  display: none;
}
.service-box-style-01 {
  margin-bottom: 45px;
}

.sb-icon {
  margin-bottom: 1rem;
  img {
    width: 70px;
  }
}

.sb-content {
  .title {
    color: #fff;
  }
  p {
    color: #c1c1c1;
  }
}

.we-provide {
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)),
    url("./img/girls.png");
  background-size: cover;
  background-position: 50% 0;

  background-attachment: fixed;
  background-repeat: no-repeat;
}

@mixin mQ($rem) {
  @media screen and (min-width: $rem) {
    @content;
  }
}

.section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: calc(min(90rem, 85%));
  margin: 0 auto;

  @include mQ(61.25rem) {
    column-gap: 5rem;
  }
}

.test-heading {
  grid-column: span 12;
  text-transform: capitalize;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  font-weight: 700;

  @include mQ(61.25rem) {
    font-size: 4rem;
    margin-bottom: 4rem;
  }
}

.cards {
  grid-column: span 12;
  display: grid;
  gap: 2rem;

  @include mQ(61.25rem) {
    grid-column: span 5;
  }

  .card {
    cursor: pointer;
    padding: 1em;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2.188rem;
    align-items: center;
    transition: 0.3s;
    position: relative;
    border: 0.094rem solid transparent;

    img {
      display: block;
      width: 3.35rem;
      height: 3.35rem;
      border-radius: 50%;
      filter: grayscale(1);
      transition: 0.5s;
    }

    > div {
      h3 {
        text-transform: capitalize;
        font-size: 1.025rem;
      }

      p {
        text-transform: capitalize;
        color: #767676;
        font-size: 0.9rem;
      }
    }
  }
  .card.active {
    background: #fff;
    border: 0.094rem solid #0f172a14;

    .gradient {
      background-image: linear-gradient(
        to right,
        #4755690a,
        #9d0cb28a,
        #4343c899,
        #4755690a
      );
      width: 50%;
      height: 0.094rem;
      position: absolute;
      content: "";
      bottom: -0.063rem;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0.125rem 0.75rem #4343c84d;
    }
  }
  .card.active img {
    filter: grayscale(0);
  }
}

.content {
  grid-column: span 12;
  position: relative;
  width: 100%;
  overflow: inherit;
  margin-top: 2rem;

  @include mQ(61.25rem) {
    grid-column: span 7;
    margin-top: 0;
    height: auto;
  }
}

.contentBox {
  display: none;
  opacity: 0;
  @include mQ(61.25rem) {
    align-items: center;
  }

  .text {
    padding-bottom: 2rem;

    @include mQ(61.25rem) {
      padding-bottom: 0;
    }
  }

  h2 {
    transition: 0.5s;
    opacity: 0;
  }

  p {
    transition: 0.5s;
    opacity: 0;
    margin-top: 1.25rem;
  }

  span {
    display: inline-block;
    transition: 0.5s;
    opacity: 0;
    margin-top: 0.625rem;

    svg {
      width: 1.25rem;
      color: #eca633;
    }
  }
}

.contentBox.active {
  opacity: 1;
  display: block;
}
.contentBox.active h2 {
  opacity: 1;
  transition-delay: 0.5s;
}
.contentBox.active span {
  opacity: 1;
  transition-delay: 0.7s;
}
.contentBox.active p {
  opacity: 1;
  transition-delay: 0.9s;
}

footer {
  background-color: #000000;
  padding: 1rem 0;
  padding-top: 4rem;
  p {
    margin: 0;
    text-align: center;
    color: #767676;
  }
}

.custom-field {
  width: 100%;
  padding: 0.7rem;
  background-color: transparent;
  border: 0;
  border: 1px solid #828080;
  outline: none;
  border-bottom: 1px solid #c1c1c1;
  color: #fff;
  letter-spacing: 1px;

  &:focus {
    border: 1px solid #fff;
  }
}

.contact-heading {
  color: #d5d1d1;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.submit {
  padding: 0.7rem 2rem;
  border: 0;
  background-color: #fff;
  color: #000;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0.2rem;
}

.copy {
  border-top: 1px solid #c1c1c1;
  padding-top: 1rem;
  margin-top: 3rem;

  p {
    margin: 0;
  }
}
